<template>
  <!-- Online -->
  <se-badge class="border border-white" v-if="isOnline" type="success" v-bind="$attrs">
    <div class="flex items-center leading-16 pt-[1px]">
      Available
    </div>
  </se-badge>
  <!-- Offline -->
  <se-badge v-else-if="isOffline" type="default" v-bind="$attrs">
    <div class="flex items-center">
      Offline
    </div>
  </se-badge>
  <!-- Busy -->
  <se-badge v-else type="warning" v-bind="$attrs">
    <div class="flex items-center">
      Busy
    </div>
  </se-badge>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import SeBadge from '@/src/shared/ui-kit/badge/Badge';

const props = defineProps<{
  advisor: any
}>();
const isOnline = computed(() => props.advisor.status === 'online');
const isOffline = computed(() => props.advisor.status === 'offline');
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorStatus',
};
</script>
