<template>
  <se-button
    v-if="currentUser && currentUser?.have_added_funds_before && !currentUser?.active_chat"
    :size="buttonSize"
    type="secondary"
    class="ml-0 flex-grow"
    @click.prevent="sendMessage"
  >
    <se-icon name="mail" :size="18" class="mr-6" />Message
  </se-button>
  <template v-if="!isOnline">
    <se-button
      v-if="!isNotify"
      :size="buttonSize"
      type="tertiary"
      class="ml-0 flex-grow"
      @click.prevent="commitNotifyMe"
    >
      <se-icon name="bell" :size="18" class="mr-6" />Notify Me
    </se-button>
    <se-button
      v-else
      :size="buttonSize"
      type="tertiary"
      class="ml-0 flex-grow"
      @click.prevent="confirmCancelNotification"
    >
      <se-icon name="bell-off" :size="18" class="mr-6" />Cancel notify
    </se-button>
  </template>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';
import SeButton from '@/src/shared/ui-kit/button/Button';
import { usersStore } from '@/src/store/users';
import { inboxStore } from '@/src/store/inbox';
import { getGlobalProperties } from '@/src/shared/helpers/getGlobalProperties';

const props = defineProps<{
  advisor: any,
  buttonSize?: string,
  isOnline?: Boolean,
}>();

const {
  $auth, $router, $route, $toast,
} = getGlobalProperties();

const user = usersStore();
const { setNotifyMeAbout, setCancelNotifyConfirmation, isSubscribedToAdvisor } = user;
const { currentUser, isAdvisor } = storeToRefs(user);
const inbox = inboxStore();
const { forceOpenMessageModal } = inbox;

const isNotify = computed(() => currentUser.value.notify?.filter((item) => item.advisor_id === props.advisor.id).length > 0);

const sendMessage = () => {
  if (isAdvisor.value) {
    $toast({
      message: 'Unable to message another advisor',
      type: 'error',
    });
  } else {
    forceOpenMessageModal(props.advisor);
  }
};

const commitNotifyMe = () => {
  if ($auth?.check()) {
    setNotifyMeAbout(props.advisor);
  } else {
    $router.push({
      path: '/register',
      query: {
        redirect_url: $route.path,
        action: 'notifyMe',
        param: props.advisor.id,
      },
    });
  }
};

const confirmCancelNotification = () => {
  const [cancelNotification] = currentUser.value.notify.filter((item) => item.advisor_id === props.advisor.id);
  setCancelNotifyConfirmation(cancelNotification);
};
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorOfflineOptions',
};
</script>